import { Injectable } from '@angular/core';
import { ICalendarEvent, ICommittee, RRuleModel } from '@common/types';
import { MatDialog } from '@angular/material/dialog';
import { getRequired } from '@common/utils/form.helpers';
import { onlyUniqueFilter } from '@common/utils/util';
import { UUID_EMPTY } from '@common/constants';
import { Observable, switchMap } from 'rxjs';
import { CommitteeService } from '@common/services';
import { EventPlanningComponent } from '@common/dialogs/event-planning/event-planning.component';
import {
  EventPlanningClose,
  EventPlanningProps,
  EventPlanningType
} from '@common/dialogs/event-planning/types';

@Injectable({
  providedIn: 'root'
})
export class EventPlanningService {
  constructor(
    private matDialog: MatDialog,
    private committeeService: CommitteeService
  ) {}

  public openTransfer(
    committeeId: string,
    eventId: string = null,
    disconnectCall = false
  ): Observable<EventPlanningClose> {
    return this.committeeService.retrieveCommittee(committeeId).pipe(
      switchMap((committee) =>
        this.matDialog
          .open(EventPlanningComponent, {
            panelClass: ['committees-app'],
            maxWidth: '90vw',
            data: this.getDialogData(committee, eventId, EventPlanningType.transfer, null, disconnectCall),
            disableClose: true
          })
          .afterClosed()
      )
    );
  }

  public openUnplanned(committeeId: string, canceled: boolean | null = null): Observable<ICalendarEvent> {
    return this.committeeService.retrieveCommittee(committeeId).pipe(
      switchMap((committee) =>
        this.matDialog
          .open(EventPlanningComponent, {
            panelClass: ['committees-app'],
            maxWidth: '96vw',
            data: this.getDialogData(committee, null, EventPlanningType.unplannedEvent, canceled),
            disableClose: true
          })
          .afterClosed()
      )
    );
  }

  private getDialogData(
    committee: ICommittee,
    eventId: string | null,
    type: EventPlanningType,
    canceled: boolean | null = null,
    disconnectCall = false
  ): EventPlanningProps {
    const { committeeMembers, committeeMeetingRooms: roomIds, rrule } = committee.compromiseModel;
    const required = getRequired(committeeMembers);

    return {
      employeeIds: required
        .map(({ employee }) => employee.id)
        .filter((employeeId, index, array) => employeeId && onlyUniqueFilter(employeeId, index, array)),
      roomIds: roomIds.filter((e) => e !== UUID_EMPTY),
      rRule: rrule as RRuleModel,
      committeeId: committee.id,
      canceled: canceled,
      title: type === EventPlanningType.transfer ? 'Перенос события' : 'Создание внепланового события',
      disconnectCall,
      type,
      eventId
    };
  }
}
