import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { AvatarModule } from '@common/shared/components/avatar/avatar.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BusyTimelineComponent } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/busy-timeline.component';
import { CellDirective } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/directives';
import { GetBusyDateRangePipe } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/pipes';

@NgModule({
  declarations: [BusyTimelineComponent, CellDirective, GetBusyDateRangePipe],
  exports: [BusyTimelineComponent],
  imports: [CommonModule, MatIconModule, AvatarModule, MatTooltipModule, MatProgressSpinnerModule]
})
export class BusyTimelineModule {}
