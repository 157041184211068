import { IOption } from './common';
import { IRole } from './role.types';

export interface IEmployeeOption extends IOption {
  position?: IOption;
  division?: IOption;
}

export interface IEmployeeWithInfo extends IEmployee {
  position: IOption;
  division: IOption;
}

export interface IEmployeeShort {
  id: string;
  fullName: string;
  shortName: string;
  position: string;
}

export interface IEmployee {
  id: string;
  fullName: string;
  shortName: string;
  position?: IOption;
  division?: IOption;
  role?: IRole;
  roles?: IRole[];
  avatar?: string;
  status?: any;
}

export interface IEmployeeFull {
  id: string;
  fullName: string;
  shortName: string;
  name: string;
  lastName: string;
  patronymic: string;
  position: IOption;
  division: IOption;
  appRole: IOption;
  avatar: string;
  email: string;
  isActive: boolean;
  isMailNotificationOn: boolean;
}
