import { TableActionsEnum } from '@common/shared';
import {
  IAgendaItem,
  IData,
  IUploadConditionsDto,
  IResponseWrapper,
  ICommitteeItem,
  IDocument,
  IEmployee,
  IOption,
  IRrule,
  IRole,
  ICommitteeTestQuestionDto,
  RRuleModel,
  NameAndOrder,
  IEmployeeOption,
  INonSystemAgendaItemSettingConditions
} from '@common/types';
import { CommitteeMeetingRoomType } from '@common/enums';
import { IDirectory } from '@common/types/directory';
import { CommitteeIntersectionDto } from '@common/dialogs/planning-dialog/types';

export interface ICommitteeWrapper extends IResponseWrapper {
  data: ICommitteeListItem[];
}

export interface ICommitteeData extends IData {
  data: ICommitteeListItem[];
}

export interface ICommitteeListItem {
  id: string;
  name: string;
  status: number;
  division: string;
  duration: number;
  created: string;
  createdBy: IEmployee;
  createdByString?: string;
  rrule: IRrule; // maybe delete
  rruleString: string;
  canEditCommittee: boolean;
  canRemoveCommittee: boolean;
  canViewCommittee: boolean;
  isCommitee: boolean; // delete
  actions?: {
    [key in TableActionsEnum]?: {
      show: boolean;
      disabled?: boolean;
    };
  };
}

export interface ITransferHistory {
  id?: string;
  committeeEventId: string;
  committeeId: string;
  createdAt?: string;
  createdById?: string;
  from: string;
  reason: string;
  to: string;
}

export interface ICommitteeEventData {
  id: string;
  agendaOptions: IAgendaOptions;
  formationPeriod?: number;
  isUnplannedEvent?: boolean;
  meetingRooms: ICommitteeMeetingRoom[];
  responsibleOfTestDevelopment: IEmployee;
  plannedPeriodOfTestDevelopment: string;
  transferHistory: ITransferHistory[];
  materialUploadDeadline: string;
  preResolutionUploadDeadline: string;
}

export interface ICommitteeMeetingRoom {
  id: string;
  type?: CommitteeMeetingRoomType;
}

export interface ICommittee {
  id?: string;
  calendarCommitteeId?: string;
  division: IOption;
  responsible: IEmployee;
  createdBy?: IEmployee;
  createdByString?: string;
  agendaOptions: IAgendaOptions;
  agendaItems: IAgendaItem[];
  status?: number | boolean;
  members?: ICommitteeMemberDto[];
  documents: IDocument[];
  compromiseModel: ICompromiseModel;
  idealModel: IIdealModel;
  rrule: IRrule | RRuleModel;
  rruleString: string;
}

export interface IAgendaOptions {
  materialUploadConditions: IUploadConditionsDto;
  preResolutionUploadConditions: IUploadConditionsDto;
  nonSystemAgendaItemSettingConditions: INonSystemAgendaItemSettingConditions;
}

export enum CommitteeFormatId {
  noRemote = '4239ccb7-0582-4a2f-bf9a-29ecd52450c8',
  remote = '4239ccb7-0582-4a2f-bf9a-29ecd52450c9'
}

// TODO Разделить модель на создание и что получаем с бэка
export interface ICompromiseModel {
  id?: string;
  committeeGoals: NameAndOrder[];
  committeeFormat: ICommitteeItem;
  committeeFormatId: CommitteeFormatId;
  committeeKind: ICommitteeItem;
  committeeMeetingRooms: string[];
  committeeMembers: IMemberDto[];
  committeeParamAndTermMembers: ICommitteeItem[];
  committeeSubType: ICommitteeItem;
  committeeTasks: NameAndOrder[];
  committeeTestQuestions: ICommitteeTestQuestionDto[];
  committeeType: ICommitteeItem;
  deadlineForPassingTest: string;
  formationPeriod: number;
  isCoordination: boolean;
  isDecisionModel: boolean;
  hasTest: boolean;
  permissibleTransferCommittee: number;
  plannedPeriodOfTestDevelopment: string;
  protocolAccess: IEmployee;
  quorumBoundary: number;
  responsibleOfTestDevelopment: IEmployee;
  rrule: IRrule;
  rruleString: string;
  timeString: string;
  name: string;
  committeeParamAndTerms: NameAndOrder[];
  meetingRoomIds: string[];
  accessTransferWithoutAccrualSanctions: string[];
}

export interface IIdealModel {
  id?: string;
  name: string;
  committeeKind: ICommitteeItem;
  committeeType: ICommitteeItem;
  committeeSubType: ICommitteeItem;
  responsibleForUploadingDocument: IEmployee;
  deadlineForUploadingDocument: string;
  rrule: IRrule;
  rruleString: string;
  timezone: string;
  committeeMembers?: IMemberDto[];
  duration: number;
  protocolAccess: IEmployee;
  formationPeriod: number;
}

export interface IMemberDto {
  id?: string;
  memberAccesses?: number[];
  employeeId?: string;
  employee?: IEmployee;
  roleId?: string;
  role?: IRole;
  legitimateDeputy?: IEmployee;
  legitimateDeputyId?: string;
  delegateTo?: string;
  modelId?: string;
  employees?: IEmployee[];
  position?: IOption;
  positionId?: string;
  required?: boolean;
  requiredToIdealModel?: boolean;
  order?: number;
  employeeOption?: IEmployeeOption;
  hasVacancy?: boolean;
}

export interface ICommitteeMemberDto {
  id: string;
  roleId: string;
  employeeId: string;
  positionId: string;
  legitimateDeputyId: string;
}

export interface IAdditionalDocument {
  committeeId: string;
  type: IDirectory;
  name: string;
  created: string;
  document: IDocument;
  id: string;
  responsible: IEmployee;
  updated: string;
}
