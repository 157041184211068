<div
  [ngClass]="{
    'invisible max-h-px max-w-px overflow-hidden': times?.length === 0
  }"
  class="flex items-center justify-between">
  <div class="flex gap-6 items-center max-h-10 min-h-10">
    <ekp-select
      #dateSelect
      placeholder="Выберите доступную дату"
      [value]="selectedDate"
      size="lg"
      class="min-w-[300px] max-w-[300px]"
      [clearable]="false"
    >
      <ng-container *ngFor="let slot of days">
        <ekp-select-item [value]="slot.date">
          {{ slot.view }}
        </ekp-select-item>
      </ng-container>
    </ekp-select>
    <ekp-select
      #timeSelect
      [value]="selectedTime"
      placeholder="Выберите доступное время"
      size="lg"
      class="min-w-[300px] max-w-[300px]"
      [clearable]="false"
    >
      <ng-container *ngFor="let slot of times">
        <ekp-select-item [value]="slot.timeStart">
          {{ slot.view }}
        </ekp-select-item>
      </ng-container>
    </ekp-select>
    <ekp-select
      *ngIf="showReason"
      #reasonSelect
      placeholder="Выберите причину"
      size="lg"
      class="min-w-[300px] max-w-[300px]"
      [clearable]="false"
      [defaultValue]="transferReasons[0].id"
    >
      <ng-container *ngFor="let reason of transferReasons">
        <ekp-select-item
          [value]="reason.id"
          [matTooltip]="reason.name"
        >
          {{ reason.name }}
        </ekp-select-item>
      </ng-container>
    </ekp-select>
  </div>

  <ng-content></ng-content>
</div>
<div
  *ngIf="times?.length === 0"
  class="text-[16px] text-[#EE5B5B] w-full flex justify-center">На этот день нет свободных слотов..</div>
