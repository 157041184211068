import {
  AttachmentTypeEnum,
  CommitteeStatusEnum,
  NoSystemAgendaDeadlineTypeEnum,
  ResolutionResponsibleTypeEnum,
  ResponsibleTypeEnum
} from '../enums';
import { IAgendaOptions, ICommitteeMemberDto } from './committee';
import { IOption, NameAndOrder } from './common';
import { IEmployee } from './employee';
import { IRrule } from './rrule.model';
import { DaysOffFormValue } from '@common/types/form-group.types';

export interface ICommitteeItem extends IOption {
  id: string;
}

export type FormLabelType = 'block' | 'group';

export interface IUploadConditionsDto extends DaysOffFormValue {
  beforeMinute?: number;
  beforeDaysTime?: string;
  beforeDays?: number;
  responsibleId?: string;
  typeResponsibleUploading?: ResponsibleTypeEnum | ResolutionResponsibleTypeEnum;

  // TODO need to check
  responsible?: IEmployee;
  dataType?: any;
}

export interface INonSystemAgendaItemSettingConditions {
  value: number;
  loadTimeType: NoSystemAgendaDeadlineTypeEnum;
}

export interface ICommitteeTestQuestionDto {
  id?: string;
  name: string;
  url: string;
  minimumThreshold: number;
}

export interface IAgendaItemCreateDto {
  id?: string;
  title: string;
  responsibleUploadingMaterialId: string;
  responsiblePreResolutionId: string;
  repeatEvery: number;
  resolution: IAgendaItemResolutionCreateDto[];
  speakers: ISpeakerDto[];
  order: number;
}

export interface ISpeakerDto {
  employeeId: string;
  needLoadedMaterial: boolean;
  order: number;
}

export interface IAgendaItemResolutionCreateDto {
  id?: string;
  order: number;
  value: string;
}

export interface IDocument {
  id: string;
  fileName: string;
}

export interface IAttachmentDto {
  id: string;
  fileId: string;
  fileName: string;
  created?: string;
  createdBy?: IEmployee;
  linkId?: string;
  link: string;
  required: boolean;
  attachmentType: AttachmentTypeEnum;
  blackListLoadMaterial?: any[];
  blackListReadMaterial?: any[];
  canDownloadMaterial?: boolean;
  canViewMaterial?: boolean;
  eventResolutionId: string;
  violations?: string[];
}

export interface IUploadAttachment {
  target?: HTMLInputElement;
  link?: string;
  required: boolean;
}

export interface IAttachment {
  committeeEventId: string;
  agendaItemId: string;
  file?: File;
  link?: string;
  attachmentType: AttachmentTypeEnum;
  required: boolean;
  eventResolutionId?: string;
}

export interface CommitteeCreateDto {
  id?: string;
  status?: CommitteeStatusEnum;
  divisionId: string | number;
  responsibleId?: string;
  compromiseModel: CompromiseModel;
  idealModel: IdealModel;
  agendaItems: IAgendaItemCreateDto[];
  documents: string[];
  agendaOptions: IAgendaOptions;
}

export interface CommitteeCreateFromDatesDto extends CommitteeCreateDto {
  eventDates: IEventDate[];
}

export interface IEventDate {
  eventTime: string;
  transferTime: string;
}

export interface CompromiseModel {
  name?: string;
  committeeKindId?: string;
  committeeSubTypeId?: string;
  committeeFormatId?: string;
  permissibleTransferCommittee: number;
  isCoordination: boolean;
  isDecisionModel: boolean;
  duration?: number;
  rRule: IRrule;
  meetingRoomIds: string[];
  quorumBoundary: number;
  committeeMembers: ICommitteeMemberDto[];
  committeeGoals: NameAndOrder[];
  committeeTasks: NameAndOrder[];
  committeeParamAndTerms: NameAndOrder[];
  committeeParamAndTermMembers: NameAndOrder[];
  protocolAccessId: string;
  formationPeriod: number;
  responsibleOfTestDevelopmentId?: string;
  plannedPeriodOfTestDevelopment?: string;
  deadlineForPassingTest?: string;
  // TODO dont forget delete later
  committeeTestQuestions: ICommitteeTestQuestionDto[];
  hasTest: boolean;
  accessTransferWithoutAccrualSanctions: string[];
}
export interface IdealModel {
  name?: string;
  committeeTypeId?: string;
  committeeKindId?: string;
  committeeSubTypeId?: string;
  typicalJobInstructionParticipant?: IDocumentCommitteeDto;
  rRule: IRrule;
  protocolAccessId: string;
  formationPeriod?: number;
  committeeMembers: ICommitteeMemberDto[];
  responsibleForUploadingDocumentId: string;
  deadlineForUploadingDocument: string;
}

export interface IDocumentCommitteeDto {
  id?: string;
  employee?: string;
  plannedDevelopmentPeriod?: string;
  documentId?: string;
}
