import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EllipsisTooltipDirective } from '@common/directives';
import { SharedPipesModule } from '@common/pipes';
import { MaterialModule } from '@common/shared';
import { QuillModule } from 'ngx-quill';
import { FormBasicSelectComponent } from './form-basic-select/form-basic-select.component';
import { FormBlockComponent } from './form-block/form-block.component';
import { FormDatepickerComponent } from './form-datepicker/form-datepicker.component';
import { FormFileUploadComponent } from './form-file-upload/form-file-upload.component';
import { FormGroupItemComponent } from './form-group-item/form-group-item.component';
import { FormGroupComponent } from './form-group/form-group.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormItemComponent } from './form-item/form-item.component';
import { FormLabelComponent } from './form-label/form-label.component';
import { FormListComponent } from './form-list/form-list.component';
import { FormMultiselectComponent } from './form-multiselect/form-multiselect.component';
import { FormToggleComponent } from './form-toggle/form-toggle.component';
import { FormEditorComponent } from './form-editor/form-editor.component';
import { FormFixerComponent } from './form-fixer/form-fixer.component';
import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { FormAutocompleteComponent } from './form-autocomplete/form-autocomplete.component';

const COMPONENTS = [
  FormBasicSelectComponent,
  FormMultiselectComponent,
  FormInputComponent,
  FormBlockComponent,
  FormGroupComponent,
  FormItemComponent,
  FormGroupItemComponent,
  FormGroupItemComponent,
  FormLabelComponent,
  FormToggleComponent,
  FormDatepickerComponent,
  FormFileUploadComponent,
  FormListComponent,
  FormEditorComponent,
  FormFixerComponent,
  FormCheckboxComponent,
  FormAutocompleteComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPipesModule,
    MaterialModule,
    QuillModule,
    EllipsisTooltipDirective
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS]
})
export class SharedFormElementsModule {}
