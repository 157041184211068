import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IOption, IUpdateEmployeeFormGroup, IUserByAdmin } from '@common/types';

export interface IUpdateEmployeeDialogResult {
  employeeId: string;
  appRoleId: string;
  isMailNotificationOn: boolean;
}

export interface IUpdateEmployeeProps {
  employee: IUserByAdmin;
  userRoles: IOption[];
  dismissible: boolean;
}

@Component({
  selector: 'com-update-employee-dialog',
  templateUrl: './update-employee-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateEmployeeDialogComponent {
  formGroup: FormGroup<IUpdateEmployeeFormGroup>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public props: IUpdateEmployeeProps,
    public matDialogRef: MatDialogRef<UpdateEmployeeDialogComponent>
  ) {
    this.createForm();
  }

  private createForm(): void {
    const { appRole, isMailNotificationOn } = this.props.employee;

    this.formGroup = new FormGroup<IUpdateEmployeeFormGroup>({
      appRoleId: new FormControl(appRole.id as string),
      isMailNotificationOn: new FormControl(isMailNotificationOn)
    });
  }

  public confirm() {
    const { id } = this.props.employee;
    const { appRoleId, isMailNotificationOn } = this.formGroup.value;
    this.matDialogRef.close({
      employeeId: id,
      appRoleId,
      isMailNotificationOn
    });
  }
}
