<div (click)="onDiagramClick($event)" class="flex flex-col py-12 gap-5">
  <com-planning-diagram-actions
    [tabs]="tabs"
    [style.margin-left.px]="cellSize"
    [currentTab]="DiagramTab.year"
  ></com-planning-diagram-actions>
  <div class="flex flex-col gap-1">
    <com-planning-diagram-header [cellSize]="cellSize" class="flex"></com-planning-diagram-header>
    <div class="flex justify-start items-center gap-1">
      <com-planning-diagram-axios-y [cellSize]="cellSize"></com-planning-diagram-axios-y>
      <com-planning-diagram-block
        [cellSize]="cellSize"
        [weekDayMap]="weekDayMap"
        (cellClick)="cellClick.emit($event)"
      ></com-planning-diagram-block>
    </div>
    <com-planning-diagram-axios-x
      class="flex gap-1"
      [cellSize]="cellSize"
      [style.margin-left.px]="cellSize"
    ></com-planning-diagram-axios-x>
  </div>
  <com-planning-diagram-legend
    class="flex gap-6 h-[32px]"
    [style.margin-left.px]="cellSize"
    [cellSize]="cellSize"
    [legend]="legend"
  ></com-planning-diagram-legend>
</div>
