import { FreeSlotsComponent } from '@common/dialogs/planning-dialog/modules/planning-intersection/components/free-slots/free-slots.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [FreeSlotsComponent],
  exports: [FreeSlotsComponent],
  imports: [CommonModule, MatTooltipModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FreeSlotsModule {}
