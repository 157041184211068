import { ChangeDetectionStrategy, Component, Inject, Self } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnsubscribeService } from '@common/services';
import { IEmployeeShort, IEventDate, RRuleModel } from '@common/types';
import { IntersectionCommitteeService } from '@common/dialogs/planning-dialog/services/intersection-committee.service';
import { BehaviorSubject, finalize, map, takeUntil, tap } from 'rxjs';
import {
  ClosestDay,
  CommitteeIntersection,
  CommitteeIntersectionDto,
  DateOfDay,
  DayInfo,
  DayInfoDto,
  FreeSlot,
  Interval,
  Option,
  TransferInfo,
  TransferMap
} from '@common/dialogs/planning-dialog/types';
import { WeekDayCell } from '@common/dialogs/planning-dialog/modules/planning-diagram/types';
import { addMinutes, format } from 'date-fns';
import {
  INTERSECTION_TABS,
  IntersectionTab
} from '@common/dialogs/planning-dialog/modules/planning-intersection/const';
import { setTimeZone, toISO } from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { getPlanningDate, startOfDayString } from '@common/dialogs/planning-dialog/utils';
import { FuseDialogActionsEnum } from '@common/enums';
import { IntersectionDiagramService } from '@common/dialogs/planning-dialog/modules/planning-diagram/services/intersection-diagram.service';
import { LEGEND } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';

export interface IPlanningDialogProps {
  employeeIds: string[];
  employees: IEmployeeShort[];
  roomIds: string[];
  rRule: RRuleModel;
  committeeId: string;
  isDraft: boolean;
  isMemberOnlyChange: boolean;
  events?: CommitteeIntersectionDto[];
}

@Component({
  selector: 'com-planning-dialog',
  templateUrl: 'planning-dialog.component.html',
  providers: [UnsubscribeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningDialogComponent {
  public events$ = new BehaviorSubject<CommitteeIntersection[]>([]);
  public dayInfo$ = new BehaviorSubject<DayInfo | null>(null);
  public dayLoading$ = new BehaviorSubject<boolean>(false);
  public loading$ = new BehaviorSubject<boolean>(false);
  public currentTab$ = new BehaviorSubject<IntersectionTab>(INTERSECTION_TABS[0].value);

  public freeSlots: Record<DateOfDay, FreeSlot[]> | null = null;
  public planningStart: string;
  public planningEnd: string;
  public transferMap: TransferMap = {};
  public Legend = LEGEND;

  protected tabs: Option<IntersectionTab>[] = INTERSECTION_TABS;

  constructor(
    public dialogRef: MatDialogRef<PlanningDialogComponent>,
    private intersectionCommitteeService: IntersectionCommitteeService,
    public diagramService: IntersectionDiagramService,
    @Inject(MAT_DIALOG_DATA) public props: IPlanningDialogProps,
    @Self() private unsubscribe: UnsubscribeService
  ) {
    this.initDiagram();
    this.initEvents();
    this.initPlanningEventDates();
  }

  private initDiagram(): void {
    const { rRule } = this.props;
    this.diagramService.initWeekDayMap(rRule, new Date());
  }

  private initPlanningEventDates(): void {
    const { dtStart, duration } = this.props.rRule;
    this.planningStart = dtStart;
    this.planningEnd = toISO(addMinutes(new Date(dtStart), duration));
  }

  private initEvents(): void {
    const { events } = this.props;
    if (events) {
      const orderedEvents = this.addOrder(events);
      this.events$.next(orderedEvents);
      this.loadFirstIntersection(orderedEvents);
    } else {
      this.loadEvents();
    }
  }

  private loadEvents(): void {
    const { rRule, committeeId, employeeIds, roomIds, isMemberOnlyChange } = this.props;
    this.loading$.next(true);

    this.intersectionCommitteeService
      .get(isMemberOnlyChange ? null : rRule, committeeId, employeeIds, roomIds)
      .pipe(
        map((events) => {
          const ordered = this.addOrder(events);
          this.events$.next(ordered);
          return ordered;
        }),
        tap((events) => this.loadFirstIntersection(events)),
        finalize(() => this.loading$.next(false)),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private loadFirstIntersection(events: CommitteeIntersection[]): void {
    const intersection = events.find((e) => e.hasIntersection);
    if (intersection) {
      const { timeStart, id, intersectionOrder } = intersection;
      this.setPlanningDates(timeStart);
      this.getDayInfo(timeStart, id, intersectionOrder);
    } else {
      this.updateDiagram();
    }
  }

  private setPlanningDates(timeStart: string): void {
    this.planningStart = timeStart;
    this.planningEnd = toISO(addMinutes(setTimeZone(timeStart), this.props.rRule.duration));
  }

  private addOrder(events: CommitteeIntersectionDto[]): CommitteeIntersection[] {
    let order = 0;
    return events.map((e) => {
      if (e.hasIntersection) {
        order += 1;
      }
      return {
        ...e,
        intersectionOrder: e.hasIntersection ? order : null,
        transferedStart: null,
        transferedEnd: null
      };
    });
  }

  public onCellClick(cell: WeekDayCell): void {
    if (!cell.id) return;
    const { eventDate, id, intersectionOrder } = cell;
    this.setPlanningDates(eventDate);
    this.getDayInfo(eventDate, id, intersectionOrder);
    this.currentTab$.next(IntersectionTab.one);
  }

  public onTableSelect(event: CommitteeIntersection): void {
    if (!event.id) return;
    const { timeStart, id, intersectionOrder } = event;
    this.setPlanningDates(timeStart);
    this.getDayInfo(timeStart, id, intersectionOrder);
    this.currentTab$.next(IntersectionTab.one);
  }

  public onTabChange(tab: IntersectionTab): void {
    this.currentTab$.next(tab);
  }

  public onEventTransfer(info: TransferInfo): void {
    this.updateEvents(info);
    this.updateTransferMap(info);
    this.updateDiagram();
  }

  public onSave(events?: CommitteeIntersection[]): void {
    this.dialogRef.close({
      action: FuseDialogActionsEnum.CONFIRMED,
      payload: {
        events: events ? this.dateEventsMapper(events) : null
      }
    });
  }

  public onSaveAsDraft(): void {
    this.dialogRef.close({
      action: FuseDialogActionsEnum.CONFIRMED,
      payload: {
        isDraft: true
      }
    });
  }

  private dateEventsMapper(events: CommitteeIntersection[]): IEventDate[] {
    return events.map((e) => {
      return {
        eventTime: e.timeStart,
        transferTime: e.transferedStart,
        eventId: e.eventId
      };
    });
  }

  private updateTransferMap(info: TransferInfo): void {
    this.transferMap = {
      ...this.transferMap,
      [info.id]: info
    };
  }

  private updateEvents(info: TransferInfo): void {
    this.events$.next(
      this.events$.value.map((e) => {
        if (e.id === info.id) {
          const { transferStart } = info;
          e.transferedStart = transferStart;
          e.transferedEnd = toISO(addMinutes(setTimeZone(transferStart), this.props.rRule.duration));
        }
        return e;
      })
    );
  }

  private getDayInfo(date: string, id: string, order: number): void {
    this.dayLoading$.next(true);
    const [prevDate, nextDate] = this.getBordersDays(id);
    const { rRule, committeeId, employeeIds, roomIds } = this.props;

    this.intersectionCommitteeService
      .getClosestDayInfo(date, prevDate, nextDate, committeeId, employeeIds, roomIds, id, rRule)
      .pipe(
        tap((closestDay) => {
          this.freeSlots = this.getFreeSlots(closestDay);
          this.initDayInfo(closestDay[startOfDayString(date)], id, order, date);
          this.updateDiagram();
        }),
        finalize(() => this.dayLoading$.next(false)),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private updateDiagram(): void {
    const { value: dayInfo } = this.dayInfo$;
    const { value: events } = this.events$;
    this.diagramService.update(
      events,
      this.props.rRule,
      dayInfo?.dayStart,
      this.transferMap[dayInfo?.id]?.transferDay
    );
  }

  private getFreeSlots(closestDay: ClosestDay): Record<DateOfDay, FreeSlot[]> {
    const result = {} as Record<DateOfDay, FreeSlot[]>;
    for (const day in closestDay) {
      if (closestDay[day].freeSlots.length !== 0) {
        result[day] = this.setFreeSlotsInterval(closestDay[day].freeSlots);
      }
    }
    return result;
  }

  private setFreeSlotsInterval(slots: Interval[]): FreeSlot[] {
    return slots.map((interval) => {
      const start = format(new Date(interval.timeStart), 'HH:mm');
      const end = format(new Date(interval.timeEnd), ' — HH:mm');
      return {
        ...interval,
        view: start + end
      };
    });
  }

  private getBordersDays(id: string): [prev: string | null, next: string | null] {
    const events = this.events$.value;
    const middle = events.findIndex((e) => e.id === id);
    const prevEvent = events[middle - 1];
    const nextEvent = events[middle + 1];
    return [this.getBorderEventDate(prevEvent), this.getBorderEventDate(nextEvent)];
  }

  private getBorderEventDate(event: CommitteeIntersection | undefined): string | null {
    if (!event) return null;
    const { transferedStart, timeStart } = event;
    if (transferedStart) return transferedStart;
    return timeStart;
  }

  private initDayInfo(info: DayInfoDto, id: string, order: number, date: string): void {
    const { duration } = this.props.rRule;
    this.dayInfo$.next({
      ...info,
      id,
      dayStart: startOfDayString(date),
      planningDate: order ? `${order}. ${getPlanningDate(date, duration)}` : getPlanningDate(date, duration)
    });
  }
}
