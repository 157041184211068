import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IOptGroup, IOption } from '@common/types';
import { selectOptionValidator } from '@common/utils/validators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'com-form-basic-select',
  templateUrl: './form-basic-select.component.html',
  styleUrls: ['./form-basic-select.component.scss']
})
export class FormBasicSelectComponent implements OnChanges {
  @Input() clearable = false;
  @Input() control: FormControl = new FormControl();
  @Input() options: (IOption | IOptGroup)[] = [];
  @Input() hiddenOptions: IOption[] = [];
  @Input() placeholder = 'Выберите значение';
  @Input() editable = false;
  @Input() empty = false;

  @Output() select = new EventEmitter<any>();

  public isCreate = false;

  constructor(private readonly _domSanitizer: DomSanitizer) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this._onOptionsChanges(changes);
  }

  public addOption(input: string): void {
    const value = input.trim();
    const newOption = this.options.find((item) => (item as IOption).name === value);
    if (!newOption) {
      this.options.unshift({ id: value, name: value });
    }
    this.isCreate = false;
  }

  triggerEvent(evt): void {
    if (!evt) {
      this.isCreate = false;
    }
  }

  public clear(evt): void {
    evt.stopPropagation();
    this.control.reset();
  }

  private _onOptionsChanges(changes: SimpleChanges): void {
    const options = changes.options?.currentValue;
    if (options) {
      this.options = options.map((option) => ({
        ...option,
        name: this._domSanitizer.bypassSecurityTrustHtml(option.name)
      }));
      this.control?.setValidators([
        ...(this.control.hasValidator(Validators.required) ? [Validators.required] : []),
        selectOptionValidator(options)
      ]);
      this.control.updateValueAndValidity();
    }
  }
}
