import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  NoSystemAgendaDeadlineTypeEnum,
  ResolutionResponsibleTypeEnum,
  ResponsibleTypeEnum,
  RoleTypesEnum,
  RruleFreqEnum,
  RruleRepeatEnum,
  UploadMaterialsTypeEnum
} from '@common/enums';
import { IEmployeeOption } from '@common/types/employee';
import { IOption } from '@common/types/common';

export type CommitteeMainFormGroup = FormGroup<{
  responsible: FormControl<IOption>;
  division: FormControl<IOption>;
}>;

export interface IIdealFormGroup {
  id: FormControl<string>;
  responsibleForUploadingDocument: FormControl<IEmployeeOption>;
  deadlineForUploadingDocument: FormControl<string>;
}

export interface ICompromiseFormGroup {
  id: FormControl<string>;
  committeeGoals: FormArray<FormControl<string>>;
  committeeTasks: FormArray<FormControl<string>>;
  committeeParamAndTerms: FormArray<FormControl<string>>;
  isDecisionModel: FormControl<boolean>;
  isCoordination: FormControl<boolean>;
  meetingRoomIds: FormControl<string[]>;
  quorumBoundary: FormControl<number>;
  permissibleTransferCommittee: FormControl<number>;
  committeeParamAndTermMembers: FormArray<FormControl<string>>;
  plannedPeriodOfTestDevelopment: FormControl<string | null>;
  responsibleOfTestDevelopment: FormControl<IEmployeeOption>;
  accessTransferWithoutAccrualSanctions: FormControl<string[]>;
}

export interface IAgendaFormGroup extends Partial<IRruleFormGroup> {
  materialUploadConditions: FormGroup<IUploadConditionsFormGroup>;
  preResolutionUploadConditions: FormGroup<IUploadConditionsFormGroup>;
  nonSystemAgendaItemSettingConditions: FormGroup<INoSystemAgendaParams>;
  agendaItems: FormArray<FormGroup<IAgendaItemFormGroup>>;
}

export interface INoSystemAgendaParams {
  loadTimeType: FormControl<NoSystemAgendaDeadlineTypeEnum>;
  value: FormControl<number>;
}

export interface IUploadConditionsFormGroup {
  responsibleId: FormControl<string>;
  beforeDays: FormControl<number>;
  beforeMinute: FormControl<number>;
  beforeDaysTime: FormControl<string>;
  typeResponsibleUploading: FormControl<ResponsibleTypeEnum | ResolutionResponsibleTypeEnum>;
}

export interface IAgendaItemFormGroup {
  id: FormControl<string>;
  title: FormControl<string>;
  speakers: FormArray<FormGroup<ISpeakerFormGroup>>;
  resolution: FormArray<FormGroup<IResolutionFormGroup>>;
  order: FormControl<number>;
  typeUploadMaterial: FormControl<UploadMaterialsTypeEnum>;
  chosenSpeakers?: FormControl<string[]>;
  responsibleUploadingMaterialId: FormControl<string>;
  responsiblePreResolutionId: FormControl<string>;
  isCustomPeriodicity: FormControl<boolean>;
  repeatEvery: FormControl<number>;
  repeatFrom: FormControl<string>;
}

export interface ISpeakerFormGroup {
  employeeId: FormControl<string>;
  needLoadedMaterial: FormControl<boolean>;
  needLoadedPreResolution: FormControl<boolean>;
  order: FormControl<number>;
}

export interface IResolutionFormGroup {
  id: FormControl<string>;
  order: FormControl<number>;
  value: FormControl<string>;
  readonly?: FormControl<boolean>;
  selected?: FormControl<boolean>;
  isAtypical?: FormControl<boolean>;
}

export interface IRruleFormGroup {
  dtStart: FormControl<string>;
  dtstarttime?: FormControl<string>;
  timezone: FormControl<string>;
  duration: FormControl<number>;
  repeat?: FormControl<RruleRepeatEnum>;
  freq: FormControl<RruleFreqEnum>;
  interval?: FormControl<number>;
  byWeekday: FormControl<string[]>;
  byMonth: FormControl<number[]>;
  bySetPos: FormControl<number[]>;
  byMonthDay: FormControl<number[]>;
}

export interface IProtocolFormGroup {
  protocolAccessEmployee: FormControl<IEmployeeOption>;
  formationPeriod: FormControl<number>;
}

export type ProtocolFormValue = Partial<{
  protocolAccessEmployee: IOption;
  formationPeriod: number;
}>;

export interface IGeneralFormGroup {
  name: FormControl<string>;
  committeeSubTypeId: FormControl<string>;
  committeeKindId: FormControl<string>;
}

export type GeneralFormValue = Partial<{
  name: string;
  committeeSubTypeId: string;
  committeeKindId: string;
}>;

export interface IProtocolsFiltersFormGroup {
  start: FormControl<string>;
  end: FormControl<string>;
}

export interface IUpdateEmployeeFormGroup {
  appRoleId: FormControl<string>;
  isMailNotificationOn: FormControl<boolean>;
}

export interface IRolesFiltersFormGroup {
  name: FormControl<string>;
}

export interface IRoleFormGroup {
  name: FormControl<string>;
  type: FormControl<RoleTypesEnum>;
  hasDeputies: FormControl<boolean>;
  order: FormControl<number>;
  required: FormControl<boolean>;
  requiredToIdealModel: FormControl<boolean>;
  hasVacancy: FormControl<boolean>;
  hasDublicate: FormControl<boolean>;
  accesses: FormGroup<Record<string, FormControl<boolean>>>;
}

export interface IMemberFiltersFormGroup {
  employee: FormControl<IOption>;
  roleIds: FormControl<string[]>;
  typeResponsible: FormControl<number>;
}

export interface IDaysOffFormGroup {
  excludeSaturdays: FormControl<boolean>;
  excludeSundays: FormControl<boolean>;
  excludeHolidays: FormControl<boolean>;
  shiftHolidays: FormControl<boolean>;
  shiftForward: FormControl<boolean>;
}

export type DaysOffFormValue = Partial<{
  excludeSaturdays: boolean;
  excludeSundays: boolean;
  excludeHolidays: boolean;
  shiftHolidays: boolean;
  shiftForward: boolean;
}>;
