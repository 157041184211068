import { Pipe, PipeTransform } from '@angular/core';
import { Interval } from '@common/dialogs/planning-dialog/types';
import { isOverlap, setTimeZone, toISO } from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { format, isAfter, isBefore } from 'date-fns';
import { addTimeToDate } from '@common/dialogs/planning-dialog/utils';

@Pipe({
  name: 'getBusyDateRange'
})
export class GetBusyDateRangePipe implements PipeTransform {
  transform(events: Interval[], forecastStart: string, forecastEnd: string): string | null {
    const [max, min] = this.findMaxAndMinTime(
      this.restrictByPlanningWindow(events, forecastStart, forecastEnd)
    );

    if (!max || !min) return null;

    return `${format(setTimeZone(min), 'HH:mm')} - ${format(setTimeZone(max), 'HH:mm')}`;
  }

  private restrictByPlanningWindow(
    events: Interval[],
    forecastStart: string,
    forecastEnd: string
  ): Interval[] {
    const result: Interval[] = [];
    events.map((event) => {
      const start = addTimeToDate(forecastStart, event.timeStart);
      const end = addTimeToDate(forecastEnd, event.timeEnd);
      const planningStart = setTimeZone(forecastStart);
      const planningEnd = setTimeZone(forecastEnd);
      let timeStart = event.timeStart;
      let timeEnd = event.timeEnd;

      if (!isOverlap(start, end, planningStart, planningEnd, true)) {
        return;
      }

      if (isBefore(start, planningStart)) {
        timeStart = toISO(addTimeToDate(event.timeStart, forecastStart));
      }
      if (isAfter(end, planningEnd)) {
        timeEnd = toISO(addTimeToDate(event.timeEnd, forecastEnd));
      }

      result.push({
        timeStart,
        timeEnd
      });
    });

    return result;
  }

  private findMaxAndMinTime(events: Interval[]): [min: string, max: string] {
    let max = null;
    let min = null;

    events.map((event) => {
      if (!min || setTimeZone(event.timeStart).getTime() < setTimeZone(min).getTime()) {
        min = event.timeStart;
      }
      if (!max || setTimeZone(event.timeEnd).getTime() > setTimeZone(min).getTime()) {
        max = event.timeEnd;
      }
    });
    return [max, min];
  }
}
