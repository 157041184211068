<div class="pl-6 py-6 relative flex justify-center overflow-y-scroll com-perfect-scrollbar z-10">
  <div class="flex flex-col">
    <div class="absolute top-4 right-6">
      <button mat-dialog-close="">
        <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
      </button>
    </div>
    <div class="flex flex-col gap-4 w-full font-inter">
      <div class="text-2xl font-semibold tracking-tight text-center">{{ props.title }}</div>
      <div class="flex flex-col justify-between gap-2 mr-6">
        <com-planning-diagram
          [weekDayMap]="diagramService.weekDayMap$ | async"
          [cellSize]="cellSize"
          [legend]="Legends"
          [disabled]="loading$ | async"
          [ngClass]="{
            'opacity-50': loading$ | async
          }"
          (cellClick)="onCellClick($event)"
        ></com-planning-diagram>

        <div *ngIf="loading$ | async; else content" class="w-full flex justify-center min-h-10 gap-2">
          <mat-spinner [diameter]="24"></mat-spinner>
          <span class="text-planning-gray">Загрузка..</span>
        </div>

        <ng-template #content>
          <div
            class="text-[16px] text-[#EE5B5B] w-full flex justify-center"
            *ngIf="freeDaysNotFound; else freeSlots"
          >
            Мы не смогли найти свободные дни.
            {{
              props.type === EventPlanningType.transfer
                ? 'Перенос события невозможен'
                : 'Создание события невозможно'
            }}.
          </div>

          <ng-template #freeSlots>
            <com-free-slots
              [times]="freeTime"
              [days]="freeDays"
              [selectedDate]="selectedDay"
              [showReason]="props.type === EventPlanningType.transfer"
              (dayChange)="dayChange$.next($event)"
              (dateChange)="transferDate = $event"
              (reasonChange)="reason = $event"
            >
            </com-free-slots>
            <div class="flex gap-2 w-full justify-end mt-4">
              <com-button (buttonClick)="dialogRef.close()" variant="outline"
                ><span class="text-[14px]">Отмена</span></com-button
              >
              <com-button (buttonClick)="onSave()" [disabled]="!transferDate || (loading$ | async)"
                ><span class="text-[14px]">Сохранить</span></com-button
              >
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</div>
