<div class="relative flex flex-col w-full h-full">
  <button class="absolute top-0 right-0" mat-dialog-close="">
    <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
  </button>

  <div class="text-2xl font-semibold tracking-tight text-center">Редактировать пользователя</div>

  <div class="flex flex-col gap-2 grow">
    <form class="py-4" [formGroup]="formGroup">
      <com-form-label text="Роль" type="block"></com-form-label>
      <mat-form-field class="w-full">
        <mat-select formControlName="appRoleId">
          <mat-option *ngFor="let role of props.userRoles" [value]="role.id">
            {{ role.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <com-form-checkbox
        class="ml-1"
        [control]="formGroup.controls.isMailNotificationOn"
        label="Email уведомления"
      ></com-form-checkbox>
    </form>
  </div>

  <div class="flex items-center justify-center sm:justify-end gap-4">
    <button mat-stroked-button mat-dialog-close="">Отмена</button>
    <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="confirm()">
      Подтвердить
    </button>
  </div>
</div>
