<div class="flex flex-col gap-y-4">
  <h2 class="text-xl leading-8">Правила учёта выходных и праздничных дней</h2>
  <com-form-item>
    <div class="flex flex-col gap-y-2">
      <com-form-checkbox label="Правила по умолчанию" [control]="defaultControl"></com-form-checkbox>
      <ng-container *ngIf="!defaultControl.value">
        <com-form-toggle
          beforeLabel="Расчёт в календарных днях"
          afterLabel="Расчёт в рабочих днях"
          [control]="weekendControl"
        ></com-form-toggle>
        <div *ngIf="weekendControl.value" class="flex items-center gap-x-4">
          <span>Выходные дни:</span>
          <com-form-checkbox
            label="Суббота"
            [control]="formGroup.controls.excludeSaturdays"
          ></com-form-checkbox>
          <com-form-checkbox
            label="Воскресенье"
            [control]="formGroup.controls.excludeSundays"
          ></com-form-checkbox>
        </div>
        <com-form-toggle
          beforeLabel="Без учёта праздничных дней"
          afterLabel="Не выставлять на праздничные дни"
          [control]="formGroup.controls.excludeHolidays"
        ></com-form-toggle>
        <ng-container *ngIf="showShiftControls && (weekendControl.value || formGroup.value.excludeHolidays)">
          <com-form-checkbox
            label="Переносить на рабочие дни"
            [control]="formGroup.controls.shiftHolidays"
          ></com-form-checkbox>
          <com-form-toggle
            *ngIf="formGroup.value.shiftHolidays"
            beforeLabel="На ближайший назад"
            afterLabel="На ближайший вперед"
            [control]="formGroup.controls.shiftForward"
          ></com-form-toggle
        ></ng-container>
      </ng-container>
    </div>
  </com-form-item>
</div>
