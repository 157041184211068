<div class="w-screen h-screen pl-6 py-6 relative flex justify-center overflow-y-scroll com-perfect-scrollbar">
  <div class="flex flex-col">
    <div class="absolute top-4 right-6">
      <button mat-dialog-close="">
        <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
      </button>
    </div>
    <div class="flex flex-col gap-4 w-full font-inter max-w-[1318px]">
      <div class="text-2xl font-semibold tracking-tight text-center">Проверка пересечений</div>
      <div class="flex flex-col min-h-[calc(100vh-100px)] justify-between gap-2 mr-6">
        <com-planning-diagram
          [weekDayMap]="diagramService.weekDayMap$ | async"
          [disabled]="loading$ | async"
          [legend]="Legend"
          [ngClass]="{
            'opacity-50': loading$ | async
          }"
          (cellClick)="onCellClick($event)"
        ></com-planning-diagram>

        <com-planning-intersection
          *ngIf="(events$ | async | hasIntersection) || (loading$ | async); else success"
          [events]="events$ | async"
          [dayInfo]="dayInfo$ | async"
          [transferInfo]="transferMap[(dayInfo$ | async)?.id]"
          [dayLoading]="dayLoading$ | async"
          [eventsLoading]="loading$ | async"
          [employees]="props.employees"
          [planningStart]="planningStart"
          [planningEnd]="planningEnd"
          [currentTab]="currentTab$ | async"
          [duration]="props.rRule.duration"
          [draft]="props.isDraft"
          [freeSlots]="freeSlots"
          (tabChange)="onTabChange($event)"
          [tabs]="tabs"
          (tableSelect)="onTableSelect($event)"
          (eventTransfer)="onEventTransfer($event)"
          (cancel)="dialogRef.close()"
          (save)="onSave(events$.value)"
          (saveAsDraft)="onSaveAsDraft()"
        ></com-planning-intersection>

        <ng-template #success>
          <div class="w-full flex justify-center items-center h-[120px] mb-3 bg-gray-50 rounded-md">
            <div class="flex gap-2 justify-center items-center">
              <mat-icon
                class="text-green-500 icon-size-8 w-40"
                svgIcon="heroicons_outline:shield-check"
              ></mat-icon>
              <span class="text-green-500"> Пересечений не найдено, комитет доступен для сохранения </span>
            </div>
          </div>
          <footer class="flex gap-2 justify-end w-full">
            <com-button (buttonClick)="dialogRef.close()" variant="outline"
              ><span class="text-[14px]">Отмена</span></com-button
            >
            <com-button (buttonClick)="onSave()"><span class="text-[14px]">Сохранить</span></com-button>
          </footer>
        </ng-template>
      </div>
    </div>
  </div>
</div>
