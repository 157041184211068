import { ChangeActionsEnum } from '@common/enums';
import { IEmployee } from './employee';
import { IRoom } from '@common/dialogs/intersection-dialog/types/intersection-dialog.types';
import { RRuleModel } from '@common/types/rrule.model';
import { EventTypeIds } from '@common/types/calendar-api';

export interface IChange {
  action: ChangeActionsEnum;
  payload?: any;
}

export interface IOptGroup {
  label: string;
  options: IOption[];
}

export interface IOption {
  id: string | number | boolean;
  name: string;
  hint?: string;
  nameOrign?: string;
}

export interface INewOption {
  id: string;
  name: string;
}

export const mapToNewOption = ({ id, name }: IOption): INewOption => ({ id: id.toString(), name });

export interface UploadedFileDto {
  file: File;
  fileId: string;
  fileName?: string;
}

export interface Icon {
  name: string;
  color?: string;
}

export interface Button {
  text?: string;
  icon?: Icon;
  link?: string;
}

export interface NameAndOrder {
  order: number;
  name: string;
}

export interface IDrawerConfig {
  position?: 'start' | 'end';
  mode?: 'over' | 'side';
  width?: string;
}

export interface ITransferEventReq {
  newDate?: string;
  reason: string;
  disconnectCall?: boolean;
}

export interface ICalendarApiBaseWrapper {
  body: any;
  errorCode: string;
  errors: string[];
  isSuccess: boolean;
}

export interface IMeetingRoomsData {
  id: string;
  shortTitle: string;
  title: string;
}

export interface IMeetingRoomsWrapper extends ICalendarApiBaseWrapper {
  body: IMeetingRoomsData[];
}

export interface IEmployeeIntersectionData {
  employeeId: string;
  eventTemplateId: string;
  startDate: string;
  endDate: string;
  title: string;
  creatorName: string;
  members?: IIntersectionMember[];
  startTime: string;
  endTime: string;
  eventTemplateTypeId: EventTypeIds;
  props: string;
  // external
  employees?: IEmployee[];
  employeeBadges?: IBadge[];
  dateRange?: string;
}

export interface IBadge {
  value: string;
  error?: string;
  hint?: string;
}

export interface IIntersectionMember {
  memberId: string;
  roleIds: string[];
}

export interface IEmployeeIntersectionWrapper extends ICalendarApiBaseWrapper {
  body: IEmployeeIntersectionData[];
}

export interface IRoomIntersectionData {
  calendarId: string;
  confirmed: boolean | null;
  creator: IEmployee;
  description: string;
  startTime: string;
  endTime: string;
  startDate: string;
  endDate: string;
  eventTemplateId: string;
  eventTemplateTypeId: string;
  isAllDay: boolean;
  isRecurringEvent: boolean;
  props: string | null;
  rRule: RRuleModel | null;
  title: string;
  eventRooms: IRoom[];
  chainStartDate: string | null;
  chainEndDate: string | null;
  factType: null;
  dateRange?: string;
  eventId?: string;
}

export interface IRoomIntersectionWrapper extends ICalendarApiBaseWrapper {
  body: IRoomIntersectionData[];
}
