<form [formGroup]="formGroup">
  <h1 class="text-2xl text-center">Компромиссная модель</h1>
  <com-general
    [committeeTypes]="committeeTypes"
    [committeeSubTypes]="committeeSubTypes"
    [committeeKinds]="committeeKinds"
    [formValue]="updateGeneralForm$ | async"
    [modelType]="ModelsEnum.COMPROMISE"
    [isEdit]="isEdit"
    (isValid)="isGeneralFormValid = $event"
    (valueChange)="generalForm = $event"
  ></com-general>
  <com-form-block>
    <com-form-list
      [array]="formGroup.controls.committeeGoals"
      [update]="formGroup.controls.committeeGoals.length"
      labelText="Цели комитета"
      customLabelClass="w-3"
    ></com-form-list>
  </com-form-block>
  <com-form-block>
    <com-form-list
      [array]="formGroup.controls.committeeTasks"
      [update]="formGroup.controls.committeeTasks.length"
      labelText="Задачи комитета"
      customLabelClass="w-3"
    ></com-form-list>
  </com-form-block>
  <com-form-block>
    <com-form-list
      [array]="formGroup.controls.committeeParamAndTerms"
      [update]="formGroup.controls.committeeParamAndTerms.length"
      labelText="Иные условия/параметры комитета"
      customLabelClass="w-3"
    ></com-form-list>
  </com-form-block>

  <com-rrule
    [formValue]="updateRruleForm$ | async"
    [isEdit]="isEdit"
    (isValid)="isRruleFormValid = $event"
    (getFormValue)="getRruleFormValue = $event"
  ></com-rrule>
  <com-protocol
    [formValue]="updateProtocolForm$ | async"
    (isValid)="isProtocolFormValid = $event"
    (valueChange)="protocolForm = $event"
  ></com-protocol>

  <com-form-block>
    <h2 class="text-xl leading-8">Получение допуска к комитету</h2>
    <com-form-toggle
      beforeLabel="Допуск не требуется"
      afterLabel="Требуется получение допуска"
      [control]="accessControl"
    ></com-form-toggle>
    <ng-container *ngIf="accessControl.value">
      <com-form-item>
        <com-form-label
          text="Ответственный за разработку теста"
          type="block"
          [required]="accessControl.value"
        ></com-form-label>
        <com-auto-suggestion
          class="w-[calc(100%-272px)]"
          [clearable]="true"
          [form]="formGroup"
          controlName="responsibleOfTestDevelopment"
          [options]="employeeOptions$ | async"
          [update]="formGroup.controls.responsibleOfTestDevelopment.touched"
          (searchValueChange)="selectorSearch$.next($event)"
          (isClose)="employeeOptions$.next([])"
          (inputClick)="selectorSearch$.next('')"
        ></com-auto-suggestion>
      </com-form-item>
      <com-form-item>
        <com-form-label
          text="Плановый срок разработки теста"
          type="block"
          [required]="accessControl.value"
        ></com-form-label>
        <com-form-input
          [control]="formGroup.controls.plannedPeriodOfTestDevelopment"
          [min]="currentDate"
          type="date"
        ></com-form-input>
      </com-form-item>
    </ng-container>
  </com-form-block>

  <com-form-block>
    <h2 class="text-xl leading-8">Формат проведения комитета</h2>
    <com-form-item>
      <com-form-toggle
        [control]="isNoRemoteFormat"
        beforeLabel="Удалённое подключение"
        afterLabel="Очное присутствие / гибридный формат"
      ></com-form-toggle>
    </com-form-item>
    <com-form-item *ngIf="isNoRemoteFormat.value">
      <com-form-label text="Перечень переговорных" type="block" [required]="true"></com-form-label>
      <com-form-multiselect
        [control]="formGroup.controls.meetingRoomIds"
        [options]="meetingRooms"
        placeholder="Выберите переговорные"
      ></com-form-multiselect>
    </com-form-item>
  </com-form-block>
  <com-form-block>
    <h2 class="text-xl leading-8">Правила подключения и формирования графика</h2>
    <com-form-item>
      <com-form-label
        text="Минимальное значение кворума для подключения"
        matTooltip="Минимальное значение кворума для подключения"
        type="block"
        [required]="true"
      ></com-form-label>
      <com-form-group>
        <com-form-input [control]="formGroup.controls.quorumBoundary" type="number" [min]="0" [max]="100">
          <com-form-label suffix text="%" type="group" class="pl-2"></com-form-label>
        </com-form-input>
      </com-form-group>
    </com-form-item>
    <com-form-item>
      <com-form-label
        text="Количество дней переноса события комитета без санкций"
        matTooltip="Количество дней переноса события комитета без санкций"
        type="block"
        [required]="true"
      ></com-form-label>
      <com-form-group>
        <com-form-input [control]="formGroup.controls.permissibleTransferCommittee" type="number" [min]="0">
          <com-form-label
            class="min-w-[42px] text-sm opacity-60"
            suffix
            text="дней"
            type="group"
          ></com-form-label>
        </com-form-input>
      </com-form-group>
    </com-form-item>
    <com-form-item>
      <com-form-label
        text="Доступ на перенос без начисления санкций"
        matTooltip="Доступ на перенос без начисления санкций"
        type="block"
      ></com-form-label>
      <com-form-multiselect
        [control]="formGroup.controls.accessTransferWithoutAccrualSanctions"
        [options]="canTransferMembers$ | async"
      ></com-form-multiselect>
    </com-form-item>
  </com-form-block>
  <com-members
    [modelType]="ModelsEnum.COMPROMISE"
    [members]="members"
    [isEdit]="isEdit"
    [shownRoles]="shownRoles"
    [hiddenRoles]="hiddenRoles"
    (addShownRole)="addShownRole.emit($event)"
    (removeShownRole)="removeShownRole.emit($event)"
    (isValid)="isMembersFormValid = $event"
    (valueChange)="membersForm = $event"
  ></com-members>
  <com-form-block>
    <com-form-list
      [array]="formGroup.controls.committeeParamAndTermMembers"
      [update]="formGroup.controls.committeeParamAndTermMembers.length"
      labelText="Иные условия/параметры состава комитета"
      customLabelClass="w-3"
    ></com-form-list>
  </com-form-block>
</form>
