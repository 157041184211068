import { Interval } from '@common/dialogs/planning-dialog/types';

export type BusyTimeline = {
  id: string;
  fullName: string;
  isRoom: boolean;
  position: string;
  events: Interval[];
};

export type TimelineEvent = Interval & {
  timelineStart: number;
  timelineEnd: number;
  eventId?: string
};

export type TotalTimeline = BusyTimeline & {
  timeline: Timeline[];
};

export enum StatusOfEvent {
  eventTime = 'eventTime',
  userEvent = 'userEvent',
  empty = 'empty'
}

export type Timeline = {
  value: [number, number];
  status: StatusOfEvent;
  color: string;
  startTime: Date;
  endTime: Date;
  beyondPlanningWindow?: boolean;
  valueName?: string;
  event?: TimelineEvent;
};
