import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IData, IMemberSlim, PageModel } from '@common/types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TypeRepository } from '@common/modules/search-members/search-members.config';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  constructor(private readonly _http: HttpClient) {}

  public get(
    employeeId: string | null,
    roleIds: string[],
    typeResponsible: TypeRepository | null,
    pageSize: number,
    page: number
  ): Observable<IData<IMemberSlim>> {
    let params = new HttpParams().set('page', page).set('pageSize', pageSize);

    if (employeeId) {
      params = params.append('employeeId', employeeId);
    }

    if (roleIds.length > 0) {
      for (const roleId of roleIds) params = params.append('roleIds', roleId);
    }

    if (typeResponsible != null) {
      params = params.append('typeResponsible', typeResponsible);
    }

    return this._http.get<IData<IMemberSlim>>('api/committeeMember', { params }).pipe(
      map((res) => ({
        data: res.data,
        pagination: new PageModel(res)
      }))
    );
  }
}
