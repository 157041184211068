import { IRole } from '@common/types/role.types';
import { ICommitteeListItem } from '@common/types/committee';
import { IEmployee } from '@common/types/employee';

export interface IMemberSlim {
  id: string;
  employee: IEmployee;
  role: IRole;
  committee: ICommitteeListItem;
}
