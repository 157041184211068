import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { asyncScheduler, filter, map, pairwise, Subject, takeUntil } from 'rxjs';
import 'moment-timezone';
import moment from 'moment/moment';
import { RruleFreqEnum, RruleRepeatEnum } from '@common/enums';
import { TimezoneService, UnsubscribeService } from '@common/services';
import { IOption, IRrule, IRruleFormGroup, RRuleModel, DaysOffFormValue } from '@common/types';
import {
  ARRAY_LAST,
  DATE_FORMAT,
  DAYS_IN_MONTH,
  DAYS_IN_WEEK,
  MAX_COMMITTEE_DURATION,
  MIN_COMMITTEE_DURATION,
  MIN_COMMITTEE_INTERVAL,
  WEEK_CODES,
  WEEK_DAYS
} from '@common/constants';
import { Days } from 'rrule/dist/esm/src/rrule';
import { dateValidator, minTimeValidator } from '@common/utils/validators';
import { isEqual } from 'lodash';
import { FormAbstractionComponent } from '@common/shared/components/form-abstraction/form-abstraction.component';

@Component({
  selector: 'com-rrule',
  templateUrl: './rrule.component.html',
  providers: [UnsubscribeService]
})
export class RruleComponent extends FormAbstractionComponent implements OnInit, OnChanges {
  @Output() getFormValue = new EventEmitter<() => IRrule>();

  // TODO: добавить тип RruleFormValue
  @Input() formValue: IRrule;
  @Input() isEdit = false;

  public RepeatEnum = RruleRepeatEnum;
  public FreqEnum = RruleFreqEnum;
  public currentDate: string = moment().format(DATE_FORMAT);
  public formGroup: FormGroup<IRruleFormGroup> = new FormGroup<IRruleFormGroup>(
    {
      dtStart: new FormControl<string>(null, [Validators.required, dateValidator({ min: this.currentDate })]),
      dtstarttime: new FormControl<string>(null, Validators.required),
      duration: new FormControl<number>(null, [
        Validators.required,
        Validators.min(MIN_COMMITTEE_DURATION),
        Validators.max(MAX_COMMITTEE_DURATION)
      ]),
      timezone: new FormControl<string>(moment.tz.guess(), Validators.required),
      interval: new FormControl<number>(null, [Validators.required, Validators.min(MIN_COMMITTEE_INTERVAL)]),
      repeat: new FormControl<RruleRepeatEnum>({ value: null, disabled: true }, Validators.required),
      freq: new FormControl<RruleFreqEnum>(null, Validators.required),
      byWeekday: new FormControl<string[]>(null),
      byMonth: new FormControl<number[]>(null),
      bySetPos: new FormControl<number[]>(null),
      byMonthDay: new FormControl<number[]>(null)
    },
    []
  );
  public weekDays: IOption[] = WEEK_DAYS;
  public monthdayOptions: IOption[] = Array.from({ length: DAYS_IN_MONTH }).map((_, index) => ({
    id: index + 1,
    name: String(index + 1)
  }));
  public durationTimes: IOption[] = [
    { name: '20', id: 20 },
    { name: '50', id: 50 },
    { name: '80', id: 80 },
    { name: '110', id: 110 }
  ];
  public monthOptions: IOption[] = moment.months().map((month, index) => ({ id: index + 1, name: month }));

  // TODO init value in two place, its not best solution
  public daysOffForm: DaysOffFormValue = {
    excludeSaturdays: false,
    excludeSundays: false,
    excludeHolidays: false
  };

  public updateDaysOffForm$: Subject<DaysOffFormValue> = new Subject<DaysOffFormValue>();

  constructor(
    protected readonly timezoneService: TimezoneService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {
    super();
  }

  public ngOnInit(): void {
    this._valueChanges();
    this.emitFormMethods();
    this.getFormValue.emit(this._rruleFormMapper.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('formValue' in changes && this.formValue) {
      if (this.formValue.dtStart) {
        if (moment(this.formValue.dtStart).isBefore(this.currentDate)) {
          this.currentDate = moment(this.formValue.dtStart).format(DATE_FORMAT);
          this.formGroup
            .get('dtStart')
            .setValidators([Validators.required, dateValidator({ min: this.currentDate })]);
        }
        if (moment(this.formValue.dtStart).isSame(moment(), 'date')) {
          this.formGroup.get('dtStart').updateValueAndValidity();
        }
        this.formGroup.get('repeat').enable();
      }
      this.formGroup.patchValue(new RRuleModel(this.formValue), { emitEvent: false });
      this.formGroup.get('repeat').setValue(this._getRepeatValue(this.formValue), { emitEvent: false });
      asyncScheduler.schedule(() => {
        const { excludeSaturdays, excludeSundays, excludeHolidays, shiftHolidays, shiftForward } =
          this.formValue;
        this.updateDaysOffForm$.next({
          excludeSaturdays,
          excludeSundays,
          excludeHolidays,
          shiftHolidays,
          shiftForward
        });
      });
    }
  }

  public onDaysOffValueChange(daysOffForm: DaysOffFormValue): void {
    if (
      this.daysOffForm &&
      this.formValue &&
      Object.entries(daysOffForm).some(([key, value]) => value !== this.formValue[key])
    ) {
      this._checkDateTime();
    }
    this.daysOffForm = daysOffForm;
  }

  private _valueChanges(): void {
    this.formGroup
      .get('dtStart')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('dtstarttime')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('timezone')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('duration')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('byMonth')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('byMonthDay')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('interval')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('repeat')
      .valueChanges.pipe(
        pairwise(),
        map(([prev, value]) => (this.isEdit ? (!!prev || prev === 0) && prev !== value && value : value)),
        filter((value) => !!value || value === 0),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((value) => {
        this._resetValidation();
        this._checkDateTime();
        const dtStart = moment(this.formGroup.get('dtStart').value);
        const monthlyWeekday = Math.floor((dtStart.date() + DAYS_IN_WEEK - 1) / DAYS_IN_WEEK);
        this.formGroup.patchValue({
          freq: [RruleRepeatEnum.DAILY, RruleRepeatEnum.DAILY_WEEKDAY, RruleRepeatEnum.CUSTOM].includes(value)
            ? RruleFreqEnum.DAILY
            : value === RruleRepeatEnum.WEEKLY
              ? RruleFreqEnum.WEEKLY
              : [
                    RruleRepeatEnum.MONTHLY,
                    RruleRepeatEnum.MONTHLY_WEEKDAY,
                    RruleRepeatEnum.MONTHLY_LAST_FRIDAY,
                    RruleRepeatEnum.MONTHLY_LAST_DAY
                  ].includes(value)
                ? RruleFreqEnum.MONTHLY
                : [RruleRepeatEnum.YEARLY, RruleRepeatEnum.YEARLY_WEEKDAY].includes(value)
                  ? RruleFreqEnum.YEARLY
                  : null,
          interval: MIN_COMMITTEE_INTERVAL,
          byWeekday:
            value === RruleRepeatEnum.DAILY_WEEKDAY
              ? [Days.MO, Days.TU, Days.WE, Days.TH, Days.FR].map((day) => day.toString())
              : [RruleRepeatEnum.MONTHLY_WEEKDAY, RruleRepeatEnum.YEARLY_WEEKDAY].includes(value)
                ? [WEEK_CODES[dtStart.weekday()]]
                : value === RruleRepeatEnum.MONTHLY_LAST_FRIDAY
                  ? [Days.FR.toString()]
                  : null,
          byMonthDay: value === RruleRepeatEnum.MONTHLY_LAST_DAY ? [ARRAY_LAST] : null,
          byMonth: value === RruleRepeatEnum.YEARLY_WEEKDAY ? [dtStart.month() + 1] : null,
          bySetPos: [RruleRepeatEnum.MONTHLY_WEEKDAY, RruleRepeatEnum.YEARLY_WEEKDAY].includes(value)
            ? [monthlyWeekday]
            : value === RruleRepeatEnum.MONTHLY_LAST_FRIDAY
              ? [ARRAY_LAST]
              : null
        });
      });

    this.formGroup
      .get('freq')
      .valueChanges.pipe(
        filter(() => this.formGroup.get('repeat').value === RruleRepeatEnum.CUSTOM),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((value) => {
        this._resetValidation();
        this._checkDateTime();

        switch (value) {
          case RruleFreqEnum.WEEKLY:
            this.formGroup.get('byWeekday').addValidators([Validators.required]);
            break;
          case RruleFreqEnum.MONTHLY:
            this.formGroup.get('byMonthDay').addValidators([Validators.required]);
            break;
          case RruleFreqEnum.YEARLY:
            this.formGroup.get('byMonth').addValidators([Validators.required]);
            this.formGroup.get('byMonthDay').addValidators([Validators.required]);
            break;
          case RruleFreqEnum.DAILY:
          default:
            break;
        }
      });

    this.formGroup.valueChanges
      .pipe(
        map(() => this.formGroup.getRawValue()),
        pairwise(),
        map(([prev, value]) => !isEqual(prev, value) && value),
        filter((value) => !!value),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((value) => {
        if (value.dtStart) {
          this.formGroup.get('repeat').enable({ emitEvent: false });
        } else {
          this.formGroup.get('repeat').disable({ emitEvent: false });
        }
      });
  }

  private _getRepeatValue(rrule: IRrule): RruleRepeatEnum {
    const monthlyWeekday = Math.floor((moment(rrule.dtStart).date() + DAYS_IN_WEEK - 1) / DAYS_IN_WEEK);
    if (rrule.interval === MIN_COMMITTEE_INTERVAL) {
      if (rrule.freq === RruleFreqEnum.DAILY) {
        if (rrule.byWeekday?.length) {
          return RruleRepeatEnum.DAILY_WEEKDAY;
        } else {
          return RruleRepeatEnum.DAILY;
        }
      } else if (rrule.freq === RruleFreqEnum.WEEKLY) {
        if (
          rrule.byWeekday?.length === 5 &&
          [Days.MO, Days.TU, Days.WE, Days.TH, Days.FR]
            .map((day) => day.toString())
            .every((w) => rrule.byWeekday.includes(w))
        ) {
          return RruleRepeatEnum.DAILY_WEEKDAY;
        } else if (rrule.byWeekday?.length) {
          return RruleRepeatEnum.CUSTOM;
        } else {
          return RruleRepeatEnum.WEEKLY;
        }
      } else if (rrule.freq === RruleFreqEnum.MONTHLY) {
        if (rrule.bySetPos?.length === 1 && rrule.bySetPos[0] === monthlyWeekday) {
          return RruleRepeatEnum.MONTHLY_WEEKDAY;
        } else if (rrule.bySetPos?.length === 1 && rrule.bySetPos[0] === ARRAY_LAST) {
          return RruleRepeatEnum.MONTHLY_LAST_FRIDAY;
        } else if (rrule.byMonthDay?.length) {
          if (rrule.byMonthDay[0] === ARRAY_LAST) {
            return RruleRepeatEnum.MONTHLY_LAST_DAY;
          } else {
            return RruleRepeatEnum.CUSTOM;
          }
        } else {
          return RruleRepeatEnum.MONTHLY;
        }
      } else if (rrule.freq === RruleFreqEnum.YEARLY) {
        if (rrule.bySetPos?.length === 1 && rrule.bySetPos[0] === monthlyWeekday) {
          return RruleRepeatEnum.YEARLY_WEEKDAY;
        } else if (rrule.byMonthDay?.length) {
          return RruleRepeatEnum.CUSTOM;
        } else {
          return RruleRepeatEnum.YEARLY;
        }
      }
    } else {
      return RruleRepeatEnum.CUSTOM;
    }
  }

  private _resetValidation(): void {
    this.formGroup.get('interval').reset();
    this.formGroup.get('byWeekday').reset();
    this.formGroup.get('byMonth').reset();
    this.formGroup.get('bySetPos').reset();
    this.formGroup.get('byMonthDay').reset();
    this.formGroup.get('byWeekday').clearValidators();
    this.formGroup.get('byMonth').clearValidators();
    this.formGroup.get('bySetPos').clearValidators();
    this.formGroup.get('byMonthDay').clearValidators();
    this.formGroup.get('byWeekday').updateValueAndValidity({ emitEvent: false });
    this.formGroup.get('byMonth').updateValueAndValidity({ emitEvent: false });
    this.formGroup.get('byMonthDay').updateValueAndValidity({ emitEvent: false });
  }

  private _checkDateTime(): void {
    const dtStart = this.formGroup.get('dtStart').value;
    const timezone = this.formGroup.get('timezone').value;
    if (dtStart && moment.tz(dtStart, timezone).isBefore(moment(), 'date')) {
      this.currentDate = moment.tz(timezone).format(DATE_FORMAT);
      this.formGroup
        .get('dtStart')
        .setValidators([Validators.required, dateValidator({ min: this.currentDate })]);
      this.formGroup.get('dtStart').updateValueAndValidity({ emitEvent: false });
      this.formGroup.get('dtStart').markAsTouched();
    } else if (this.formGroup.get('dtstarttime').value) {
      this.formGroup
        .get('dtstarttime')
        .setValidators([
          Validators.required,
          minTimeValidator(timezone, this.formGroup.get('dtStart').value)
        ]);
      this.formGroup.get('dtstarttime').markAsTouched();
      this.formGroup.get('dtstarttime').updateValueAndValidity({ emitEvent: false });
    }
  }

  private _rruleFormMapper(): IRrule {
    return {
      ...this.formGroup.getRawValue(),
      ...this.daysOffForm
    };
  }
}
