import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'com-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state(
        'expanded',
        style({
          height: '*'
        })
      ),
      state(
        'collapsed',
        style({
          height: '0'
        })
      ),
      transition('expanded <=> collapsed', animate('200ms ease-in-out'))
    ])
  ]
})
export class FormInputComponent implements AfterViewInit, OnChanges {
  @Input() clearable = false;
  @Input() readonly = false;
  @Input() control: FormControl<string | number> = new FormControl<string>(null);
  @Input() type = 'text';
  @Input() placeholder = 'Введите значение';
  @Input() preIcon: string;
  @Input() postIcon: string;
  @Input() max: number | string = null;
  @Input() min: number | string = 1;
  @Input() isMultiline = false;
  @Input() step: 600 | 900 | 1800 | 3600 | null = null;
  @Input() hint: string | null = null;
  @Input() alwaysShowHint = false;

  public showHint = false;

  @ViewChild('textarea', { read: CdkTextareaAutosize })
  textarea: CdkTextareaAutosize;

  ngAfterViewInit(): void {
    this.textarea?.resizeToFitContent(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('type' in changes) {
      switch (changes.type.currentValue) {
        case 'datetime-local':
          this.max = '9999-12-31T23:59';
          break;
        case 'date':
          if (!this.max) {
            this.max = '9999-12-31';
          }
          break;
      }
    }
  }

  public onShowHint(): void {
    if (this.type === 'time' && this.step) {
      this.showHint = true;
    }
  }

  public checkData(): void {
    if (this.type === 'time' && this.step && this.control.value) {
      this.showHint = false;
      const time = this.control.value.toString().split(':');
      const minute = Number(time[1]);
      const hour = Number(time[0]);
      if (hour == 19 && minute > 0) {
        time[0] = '19';
        time[1] = '00';
      } else {
        if ((minute >= 0 && minute < 15) || (minute > 45 && minute <= 0)) {
          time[1] = '00';
        } else {
          time[1] = '30';
        }
        if (hour >= 0 && hour < 8) {
          time[0] = '08';
          time[1] = '00';
        } else if (hour >= 20 && hour <= 24) time[0] = '19';
      }

      this.control.setValue(time.join(':'));
    }
  }

  public clear(evt): void {
    evt.stopPropagation();
    this.control.reset();
  }
}
