import { Route } from '@angular/router';
import { AuthGuard, CheckInGuard, CheckOutGuard } from '@common/guards';
import { AdminGuard } from '@common/guards/admin.guard';
import { EventRedirectComponent } from '@common/modules/event-redirect/event-redirect.component';
import { LayoutComponent } from './layout.component';

export const layoutRoutes: Route[] = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'calendar', pathMatch: 'full' },
      {
        path: 'profile',
        loadChildren: () => import('../profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: 'calendar',
        loadChildren: () => import('../calendar/calendar.module').then((m) => m.CalendarModule)
      },
      {
        path: 'call/:eventId',
        canActivate: [CheckInGuard],
        canDeactivate: [CheckOutGuard],
        loadChildren: () => import('@common/modules/call/call.module').then((m) => m.CallModule)
      },
      {
        path: 'committees',
        loadChildren: () => import('../committees/committees.module').then((m) => m.CommitteesModule)
      },
      {
        path: 'users',
        canActivate: [AdminGuard],
        loadChildren: () => import('../users/users.module').then((m) => m.UsersModule)
      },
      {
        path: 'roles',
        canActivate: [AdminGuard],
        loadChildren: () => import('../roles/roles.module').then((m) => m.RolesModule)
      },
      {
        path: 'documents',
        canActivate: [AdminGuard],
        loadChildren: () => import('../documents/documents.module').then((m) => m.DocumentsModule)
      },
      {
        path: 'eventRedirect/:committeeId/:startDate',
        component: EventRedirectComponent
      }
    ]
  },
  { path: '**', redirectTo: '' }
];
