// FIXME: включить eslint и исправить ошибки
/* eslint-disable */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommitteeCreateDto,
  CommitteeCreateFromDatesDto,
  ICommittee,
  ICommitteeData,
  ICommitteeEventData,
  ICommitteeItem,
  ICommitteeWrapper,
  IPage,
  PageModel,
  RRuleModel,
  IEventDate
} from '@common/types';
import { map, Observable } from 'rxjs';
import { compareByField } from '@common/utils/util';
import { CommitteeBelongingEnum, CommitteeStatusEnum } from '@common/enums';
import { CommitteeIntersectionDto } from '@common/dialogs/planning-dialog/types';

interface ICommitteeRequest extends Partial<IPage> {
  name?: string;
  divisionId?: string;
  belonging?: CommitteeBelongingEnum;
  status?: CommitteeStatusEnum;
}

@Injectable({
  providedIn: 'root'
})
export class CommitteeService {
  constructor(private readonly _http: HttpClient) {}

  public canCreateCommittee(): Observable<boolean> {
    return this._http.get<boolean>('api/Committee/CanCreate');
  }

  //TODO дубель есть такой же в сервисе form.service.ts
  public retrieveCommitteeDivisions(): Observable<ICommitteeItem[]> {
    return this._http.get<ICommitteeItem[]>('api/Division');
  }

  public checkAccessCommittee(id: string, access: number): Observable<boolean> {
    const params = new HttpParams().set('committeeId', id).set('access', access);

    return this._http.get<boolean>('api/Committee/CheckAccessCommittee', {
      params
    });
  }

  public getCommitteeDataForCommitteeEvent(id: string): Observable<ICommitteeEventData> {
    return this._http.get<any>(`api/Committee/committeeDataForCommitteeEvent/${id}`);
  }

  public retrieveCommittees(options?: ICommitteeRequest): Observable<ICommitteeData> {
    let params = new HttpParams().set('page', options?.page || 0).set('pageSize', options?.pageSize || 10);

    if (options?.name) {
      params = params.append('name', options.name);
    }
    if (options?.divisionId) {
      params = params.append('divisionId', options.divisionId);
    }
    if (
      [CommitteeStatusEnum.HELD, CommitteeStatusEnum.NOT_HELD, CommitteeStatusEnum.DRAFT].includes(
        options?.status
      )
    ) {
      params = params.append('status', options.status);
    }

    if (options?.belonging === CommitteeBelongingEnum.MY) {
      params = params.append('whereUserExist', true);
    }
    return this._http.get('api/Committee/CommiteeAndMeeting', { params }).pipe(
      map((res: ICommitteeWrapper) => ({
        data: res.data.map((item) => ({
          ...item,
          timeString: RRuleModel.timeString(item.rrule),
          rruleString: RRuleModel.toString(item.rrule)
        })),
        pagination: new PageModel(res)
      }))
    );
  }

  public postCommittee(committee: CommitteeCreateDto): Observable<unknown> {
    return this._http.post<any>('api/Committee', committee);
  }

  public postCommitteeFromEventDates(committee: CommitteeCreateFromDatesDto): Observable<unknown> {
    return this._http.post<unknown>('api/Committee/CreateFromDates', committee);
  }

  public putCommitteeFromEventDates(committee: CommitteeCreateFromDatesDto): Observable<unknown> {
    return this._http.put<unknown>('api/Committee/UpdateFromDates', committee);
  }

  public putCommittee(committee: CommitteeCreateDto): Observable<any> {
    return this._http.put<any>('api/Committee', committee);
  }

  public retrieveCommittee(id: string): Observable<ICommittee> {
    return this._http.get<ICommittee>(`api/Committee/${id}`).pipe(
      map((committee) => {
        return {
          ...committee,
          agendaItems: committee.agendaItems.sort(compareByField('order')).map((agendaItem) => ({
            ...agendaItem,
            resolution: agendaItem.resolution.sort(compareByField('order'))
          })),
          idealModel: {
            ...committee.idealModel,
            timeString: RRuleModel.timeString(committee.idealModel.rrule),
            rruleString: RRuleModel.toString(committee.idealModel.rrule),
            rrule: committee.idealModel.rrule
          },
          compromiseModel: {
            ...committee.compromiseModel,
            timeString: RRuleModel.timeString(committee.compromiseModel.rrule),
            rruleString: RRuleModel.toString(committee.compromiseModel.rrule),
            rrule: committee.compromiseModel.rrule,
            committeeGoals: committee.compromiseModel.committeeGoals.sort(compareByField('order')),
            committeeTasks: committee.compromiseModel.committeeTasks.sort(compareByField('order')),
            committeeParamAndTerms: committee.compromiseModel.committeeParamAndTerms.sort(
              compareByField('order')
            ),
            committeeParamAndTermMembers: committee.compromiseModel.committeeParamAndTermMembers.sort(
              compareByField('order')
            )
          }
        };
      })
    );
  }

  public complete(id: string): Observable<unknown> {
    return this._http.post<unknown>(`api/Committee/${id}/complete`, {});
  }

  public resume(id: string, dates?: IEventDate[]): Observable<CommitteeIntersectionDto[] | null> {
    return this._http.post<CommitteeIntersectionDto[] | null>(`api/Committee/${id}/resume`, { dates });
  }
}
