import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  CommitteeIntersection,
  DateOfDay,
  DayInfo,
  FreeDay,
  FreeSlot,
  Interval,
  Option,
  RoomId,
  TransferInfo,
  UserId
} from '@common/dialogs/planning-dialog/types';
import { IntersectionTab } from '@common/dialogs/planning-dialog/modules/planning-intersection/const';
import { BusyTimeline } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/types';
import { IEmployeeShort } from '@common/types';
import { GetBusyDateRangePipe } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/pipes/get-busy-date-range.pipe';
import { toISO } from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { getFreeDays, getPlanningDate, startOfDayString } from '@common/dialogs/planning-dialog/utils';
import { FreeSlotSelect } from '@common/dialogs/planning-dialog/modules/planning-intersection/types';
import { IntersectionService } from '@common/services';
@Component({
  selector: 'com-planning-intersection',
  templateUrl: 'planning-intersection.component.html',
  providers: [GetBusyDateRangePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningIntersectionComponent implements OnChanges {
  @Input() events: CommitteeIntersection[] = [];
  @Input() dayInfo: DayInfo | null;
  @Input() transferInfo: TransferInfo | undefined;
  @Input() dayLoading: boolean;
  @Input() eventsLoading: boolean;
  @Input() employees: IEmployeeShort[];
  @Input() tabs: Option<IntersectionTab>[] = [];
  @Input() currentTab: IntersectionTab;
  @Input() planningStart: string;
  @Input() planningEnd: string;
  @Input() draft: boolean;
  @Input() duration: number;
  @Input() freeSlots: Record<DateOfDay, FreeSlot[]>;

  @Output() tableSelect = new EventEmitter<CommitteeIntersection>();
  @Output() tabChange = new EventEmitter<IntersectionTab>();
  @Output() eventTransfer = new EventEmitter<TransferInfo>();
  @Output() save = new EventEmitter<void>();
  @Output() saveAsDraft = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  public timeline: BusyTimeline[] = [];
  public freeDays: FreeDay[] = [];
  public selectedFreeSlotDay: string | null = null;
  public selectedTransferDate: FreeSlotSelect | null = null;

  protected Boolean = Boolean;
  protected IntersectionTab = IntersectionTab;

  constructor(
    private getBusyDateRangePipe: GetBusyDateRangePipe,
    private intersectionService: IntersectionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('dayInfo' in changes && this.dayInfo) {
      this.timeline = this.getTimeline(this.dayInfo);
    }
    if ('freeSlots' in changes && this.freeSlots) {
      this.freeDays = getFreeDays(this.freeSlots);
      this.selectedFreeSlotDay = this.dayInfo.dayStart;
    }
    if ('transferInfo' in changes && this.transferInfo) {
      this.selectedFreeSlotDay = this.transferInfo.transferDay;
    }
    if ('loading' in changes) {
      this.selectedTransferDate = null;
    }
  }

  private getTimeline({ busyMembers, busyRooms }: DayInfo): BusyTimeline[] {
    return [...this.getMembersTimeline(busyMembers), ...this.getRoomsTimeline(busyRooms)];
  }

  private getMembersTimeline(busyMembers: Record<UserId, Interval[]>): BusyTimeline[] {
    const result: BusyTimeline[] = [];
    for (const userId in busyMembers) {
      if (this.getBusyDateRangePipe.transform(busyMembers[userId], this.planningStart, this.planningEnd)) {
        const { fullName, position } = this.employees.find((u) => u.id === userId);
        result.push({
          id: userId,
          position,
          fullName,
          isRoom: false,
          events: busyMembers[userId]
        });
      }
    }
    return result;
  }

  private getRoomsTimeline(busyRooms: Record<RoomId, Interval[]>): BusyTimeline[] {
    const result: BusyTimeline[] = [];
    for (const roomId in busyRooms) {
      if (this.getBusyDateRangePipe.transform(busyRooms[roomId], this.planningStart, this.planningEnd)) {
        result.push({
          id: roomId,
          fullName: this.intersectionService.getRoomName(roomId),
          position: null,
          isRoom: true,
          events: busyRooms[roomId]
        });
      }
    }
    return result;
  }

  public transferEvent(): void {
    this.eventTransfer.emit({
      id: this.dayInfo.id,
      transferDateInterval: getPlanningDate(this.selectedTransferDate.date, this.duration),
      transferStart: toISO(this.selectedTransferDate.date),
      transferDay: startOfDayString(this.selectedTransferDate.date),
      select: this.selectedTransferDate
    });
  }
}
