import { IOption } from '@common/types/common';

export interface ITask {
  id: string;
  title: string;
  assignee: ITaskAssignee;
  assigneeHistory: ITaskAssigneeHistoryItem[];
  completeDate: string;
  committeeEventTime: string;
  createdAt: string;
  deadline: ITaskDeadline;
  deadlines: ITaskDeadline[];
  historyStatus: IProjectStatusHistoryItem[];
  taskStatus: IProjectStatusHistoryItem;
  resultRepresentation: string;
  order: number;
  taskLink: string;
  isTask: boolean;
  agendaItemId: string;
  agendaItemTitle: string;
  agendaItemOrder: number;
  assigneeId?: string;
  assigneeArray?: string[];
  deadlineArray?: string[];
  projectStatusString?: string;
}

export interface ITaskAssignee {
  id: string;
  name?: string;
}

export interface ITaskAssigneeHistoryItem extends ITaskHistoryItem {
  assignee: ITaskAssignee;
}

export interface ITaskDeadline extends ITaskHistoryItem {
  id?: string;
  deadline: string;
}
export interface IProjectStatusHistoryItem extends ITaskHistoryItem {
  projectStatus: ITaskProjectStatus;
}

export interface ITaskProjectStatus {
  id: string;
  status: string;
  is_final: boolean;
}

export interface ITaskHistoryItem {
  createdAt: string;
}

export interface ITaskEditData {
  assigneeId?: string;
  deadline?: string;
  projectStatusId?: string;
  memberOptions?: IOption[];
  statusOptions?: IOption[];
  projectStatuses?: ITaskProjectStatus[];
}

export interface ITaskChange {
  task: ITask;
  change: ITaskEditData;
}

export interface ITaskDataSource {
  agendaItemTitle: string;
  agendaItemOrder: number;
  tasks: ITask[];
}
