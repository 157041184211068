import {RRuleModel} from "@common/types";

export type EventPlanningProps = {
  employeeIds: string[];
  eventId: string | null;
  roomIds: string[];
  rRule: RRuleModel;
  committeeId: string;
  type: EventPlanningType;
  canceled: boolean | null;
  title: string;
  disconnectCall: boolean;
};

export type EventPlanningClose = {
  reason: string;
  eventTime: string;
}

export enum EventPlanningType {
  transfer,
  unplannedEvent
}
